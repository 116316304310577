<template>
  <div class="app"></div>
</template>
<script>
import updataApi from "../../api/updataApi";
import companyApi from "../../api/companyApi";

export default {
  data() {
    return {
      input: "",
      // 角色信息
      code: "",
      // 待审核
      padding: true,
      // 企业状态
      companystate: "",
    };
  },

  created() {
    // this.getCurrCompany();
    this.getCurrUserRole();
  },
  methods: {
    //  获取当前角色信息
    getCurrUserRole() {
      updataApi.getCurrUserRole("", (res) => {
        if (res.data.result.code == "ordinary") {
          this.$router.push("unverified");
        } else {
          this.getCompanySate();
        }
      });
    },
    // 获取当前企业状态
    getCompanySate() {
      companyApi.getCurrCompanySate((res) => {
        this.companystate = res.data.result.state;
        localStorage.setItem("companystate", this.companystate);
        if (res.data.result.state == 0||res.data.result.state == 2) {
          this.$router.push("audit");
        } else {
          this.$router.push("authenticated");
        }
      });
    },
  },
};
</script>
