<template>
  <div class="app">
    <div class="left">
      <ul>
        <h3>账号管理</h3>
        <li>
          <router-link :to="{ name: 'PersonalCenter' }"
            ><a
              :class="{ active: $store.state.currPage == 'PersonalCenter' }"
              href="javascript:;"
              >个人信息</a
            ></router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'Entrepreneur' }"
            ><a
              href="javascript:;"
              :class="{ active: $store.state.currPage == 'Entrepreneur' }"
              >所属企业</a
            ></router-link
          >
        </li>
        <li
          v-if="
            $store.state.home.userInfostate == 'companyAdmin' ||
            $store.state.home.userInfostate == 'companyOrdinary'
          "
        >
          <router-link :to="{ name: 'Administrator' }"
            ><a
              href="javascript:;"
              :class="{ active: $store.state.currPage == 'Administrator' }"
              >管理员权限</a
            ></router-link
          >
        </li>
        <li v-if="$store.state.home.userInfostate == 'companyAdmin'">
          <router-link :to="{ name: 'Statement' }"
            ><a
              href="javascript:;"
              :class="{ active: $store.state.currPage == 'Statement' }"
              >收支明细</a
            ></router-link
          >
        </li>
        <h3 v-if="$store.state.home.userInfostate == 'companyAdmin'">企业管理</h3>
        <li v-if="$store.state.home.userInfostate == 'companyAdmin'">
          <router-link :to="{ name: 'Commodity' }"
            ><a
              href="javascript:;"
              :class="{ active: $store.state.currPage == 'Commodity' }"
              >商品管理</a
            ></router-link
          >
        </li>
        <li v-if="$store.state.home.userInfostate == 'companyAdmin'">
          <router-link :to="{ name: 'Transaction' }"
            ><a
              href="javascript:;"
              :class="{ active: $store.state.currPage == 'Transaction' }"
              >交易记录</a
            ></router-link
          >
        </li>
         <li  v-if="$store.state.home.userInfostate=='companyAdmin'"> <router-link :to="{name: 'Collect'}" ><a href="javascript:;" :class="{active:$store.state.currPage == 'Collect'}" >发布信息</a></router-link></li>
        <li ><router-link  :to="{name: 'Release'}"  ><a href="javascript:;" :class="{active:$store.state.currPage == 'Release'}" >收藏</a></router-link></li>
        <h3>帮助中心</h3>
        <li @click="chatonechat">
          <router-link :to="{ name: 'contact' }"
            ><a
              href="javascript:;"
              :class="{ active: $store.state.currPage == 'contact' }"
              >消息列表</a
            ></router-link
          >
        </li>
        <li>
          <router-link to="/Support"
            ><a href="javascript:;">联系客服</a></router-link
          >
        </li>
      </ul>
    </div>

    <div class="right">
      <!-- <component :is='currentView' keep-alive></component> -->
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import imApi from "../api/imApi";
import updataApi from "../api/updataApi";
import { Message } from "element-ui";
import personalCenter from "./center/personalCenter";
import entrepreneur from "./center/entrepreneur";
import administrator from "./center/administrator";
import statement from "./center/statement";
import commodity from "./center/commodity";
import transaction from "./center/transaction";
import collect from "./center/collect";
import release from "./center/release";
import customer from "./center/customer";
import userApi from "../api/updataApi";
import { log } from "../utils/EMedia_x1v1_3.4.1";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default {
  data() {
    return {
      username: userInfo.userId || "",
      password: userInfo.password || "1",
      nickname: "",
      personalCenterText: "personalCenter",
      statementText: "statement",
      entrepreneurText: "entrepreneur",
      administratorText: "administrator",
      commodityText: "commodity",
      transactionText: "transaction",
      collectText: "collect",
      releaseText: "release",
      customerText: "customer",
      currentView: "personalCenter",
      act_index: 1,
    };
  },
  components: {
    personalCenter,
    entrepreneur,
    administrator,
    commodity,
    transaction,
    collect,
    release,
    customer,
    statement,
  },
  created() {
  
  },

  methods: {
    chatonechat() {
      userApi.getUseridentityStates("", (res) => {
       
        if (res.data.result == false) {
          Message.error("请进行个人认证");
          this.$router.push("/home/personalCenter");
        }
      });
    
    },

    tabToggle: function (tabText) {
      this.currentView = tabText;
    },
    hope() {
      this.$router.push("/support");
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.app {
  display: flex;
}
.left {
  margin-top: 40px;
}
.left ul {
  width: 130px;
  height: 340px;
  border-radius: 10px;
  text-align: center;
  position: sticky;
  top: 100px;
}
.left ul li {
  list-style: none;
  text-decoration: none;
  /* border: 1px solid red; */
  width: 120px;
  height: 28px;

  line-height: 28px;
  margin: 14px;
}

h3 {
  width: 120px;
  /* border: 1px solid red; */
  margin: 14px;
  font-size: 20px;
}
.left ul li a {
  text-decoration: none;
  /* border: 1px solid red; */
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
}
.left ul li a.active {
  color: red !important;
}
.right {
  margin-left: 15%;
  /* border: 1px solid red;  */
  /* border: 1px solid red; */
  /* margin-top: 20px; */
}
span {
  /* border: 1px solid red; */
  display: block;
  width: 80px;
  height: 20px;
  margin-left: 38px;
  /* padding-top: 20px; */
}
</style>
