<template>
  <div class="home">
    <top></top>
  <div class="center">
    <div class="nav-wrap">
   
     
     <Tab></Tab>
      
    </div>
  </div>
  <div class="footer">
    <Foo></Foo>
     </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import Tab from "@/components/Tab.vue";
import userApi from '../api/updataApi';
export default {
  data(){
    return{
        activeName: 'second',
        name:"",
         tabPosition: 'left',
         token:"",
         
    }
  },
  created(){
    // this.gettoken()
     this.getuserinfor()
    userApi.getcurruserinfo("",(res)=>{
   
      if(res.data.code==601){
           this.$router.push("/login")
      }
      let nickName = res.data.result.nickname;
      this.$store.commit("initnickName",nickName)
    })
  },
  name: 'Home',
  components: {
    // HelloWorld
    Top,
    Foo,
    Tab
  },
  methods:{
        // 获取当前角色信息
        getuserinfor(){
           // 获取当前角色信息
              userApi.getCurrUserRole("", (res) => {
              
                this.$store.commit("inituserInfostate",res.data.result.code)
             
              })
        },
       handleOpen(key, keyPath) {
      
      },

  },
  mounted(){
   
  }
}
</script>
<style scoped>
.home{
  min-width: 1250px;
}
.center{
 width: 100%;
 min-height: 720px;
padding-bottom: 20px;
background: #FDFDFD;
 /* padding-top: 50px; */
  /* border: 1px solid red; */
}

.home{
  background-color: rgb(245,245,245);
   
}
.nav-wrap{
  /* border: 1px solid red; */
   
  width: 68%;
  margin: auto;
   /* margin-top: 40px; */
   padding-top: 50px;
}

.item ul{
  width: 120px;
  height: 350px;
 /* border: 1px solid red; */
}
.item ul li{
/* border: 1px solid rgb(99, 13, 236); */
list-style: none;
 margin-top: 15px;
 cursor: pointer;
}
/* .footer{
  border: 1px solid red;
  
} */

</style>
