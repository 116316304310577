<template>
    <div class="app">
        08
    </div>
</template>
<script>
export default {
    
}
</script>

<style scoped>
   .app{
         border: 1px solid red;
   margin-left: -150px;
   }
</style>